/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-use-before-define */
/* eslint-disable react/destructuring-assignment */

import * as React from 'react';
import AbstractInput from '../forms/AbstractInput/AbstractInput';

interface Props {
  value: string;
  onWrapperClick: () => void;
  isDayPickerShown: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder: string;
  disabled?: boolean;
}

const InputWrapper = ({ value, disabled, placeholder, ...otherProps }: Props) => (
  <AbstractInput
    disabled={disabled}
    component="datepicker"
    value={value || placeholder}
    selectIconColor="dark"
    size="md"
    onClick={otherProps.onWrapperClick}
    {...otherProps}
  />
);

export default InputWrapper;
