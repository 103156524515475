/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import * as React from 'react';

// import 'react-day-picker/dist/style.css';
import './DatePicker.css';
import { format } from 'date-fns';
import { DayPicker, DateFormatter, Matcher } from 'react-day-picker';
import { usePopper } from 'react-popper';

import DateTime from './DateTime/DateTime';
import InputWrapper from './InputWrapper';
import Header from './Header';
import Footer from './Footer/Footer';
import { DEFAULT_DATE_FORMAT, DayType } from './constants';
import Span from '../Span/Span';
import A from '../Links/A';

interface Props {
  selectedDate: Date;
  startDate?: Date;
  endDate?: Date;
  twoMonths?: boolean;
  onDateSelect?: (Date) => void;
  onSelectTimeClick?: () => void;
  customText?: string;
  inputClass?: string;
  fixedPosition?: boolean;
  headerClassName?: string;
  disabledDays?: Matcher[];
  showUPSPolicy?: boolean;
  dates?: {
    freeShipping: Date[];
    critical: Date[];
    standard: Date[];
  };
  dataCy?: string;
}

const formatCaption: DateFormatter = (month, options) => (
  <> {format(month, 'LLL yyyy', { locale: options?.locale })}</>
);

const formatWeekdayName: DateFormatter = (day, options) => (
  <> {format(day, 'iii', { locale: options?.locale })}</>
);

const dayTypeToClassName = (dayType: DayType | null): string => {
  switch (dayType) {
    case DayType.FREE_SHIPPING:
      return '!bg-green-500 !border-green-50 !border-4 !text-white hover:!text-white hover:!bg-green-500';
    case DayType.CRITICAL:
      return '!bg-orange-700 !border-orange-50 !border-4 !text-white hover:!text-white hover:!bg-orange-700';
    case DayType.STANDARD:
      return '!bg-blue-500 !border-blue-50 !border-4 !text-white hover:!text-white hover:!bg-blue-500';
    default:
      return '';
  }
};

const GuaranteedDatesInfo = () => (
  <div className="flex w-full flex-col rounded-t-lg bg-yellow-50 px-1 py-2 text-center">
    <Span className="text-sm">
      Delivery dates are no longer guaranteed due to{' '}
      <A
        href="https://www.ups.com/us/en/help-center/shipping-support/service-guarantee.page"
        color="black"
        underline="always"
        targetBlank
      >
        UPS&apos;s change in policy.
      </A>
    </Span>
  </div>
);

const DatePicker = (props: Props) => {
  const {
    customText,
    selectedDate,
    onSelectTimeClick,
    headerClassName,
    disabledDays,
    twoMonths,
    dates,
    showUPSPolicy,
    onDateSelect,
    dataCy,
  } = props;

  const { freeShipping, critical, standard } = dates ?? {};

  const wrapperRef = React.useRef<HTMLDivElement>(null);
  const [inputValue, setInputValue] = React.useState<string>('');
  const popperRef = React.useRef<HTMLDivElement>(null);
  const [popperElement, setPopperElement] = React.useState<HTMLDivElement | null>(null);
  const popper = usePopper(popperRef.current, popperElement, {
    placement: 'bottom-start',
  });
  const [datepickerShown, setDatepickerShown] = React.useState<boolean>(false);

  const handleInputChange = e => {
    setInputValue(e.currentTarget.value);
  };

  const handleDaySelect = (date: Date) => {
    if (date) {
      setInputValue(format(date, DEFAULT_DATE_FORMAT));
      if (!onSelectTimeClick) {
        setDatepickerShown(false);
      }
    } else {
      setInputValue('');
    }

    if (onDateSelect) {
      onDateSelect(date);
    }
  };

  const handleSelectTimeClick = () => {
    if (onSelectTimeClick) {
      onSelectTimeClick();
      setDatepickerShown(false);
    }
  };

  const toggleDayPicker = () => {
    setDatepickerShown(!datepickerShown);
  };

  const handleClickOutside = event => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setDatepickerShown(false);
    }
  };

  React.useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return function unmount() {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const getDayType = (date: Date): DayType | null => {
    const formattedDate = format(date, 'yyyy-MM-dd');

    if (freeShipping?.map(d => format(d, 'yyyy-MM-dd')).includes(formattedDate)) {
      return DayType.FREE_SHIPPING;
    }
    if (critical?.map(d => format(d, 'yyyy-MM-dd')).includes(formattedDate)) {
      return DayType.CRITICAL;
    }
    if (standard?.map(d => format(d, 'yyyy-MM-dd')).includes(formattedDate)) {
      return DayType.STANDARD;
    }

    return null;
  };

  const selectedDayType = getDayType(selectedDate);
  const selectedDayTypeClass = dayTypeToClassName(selectedDayType);

  return (
    <div data-cy={dataCy} ref={wrapperRef}>
      <div ref={popperRef}>
        <InputWrapper
          value={customText || inputValue}
          onWrapperClick={toggleDayPicker}
          isDayPickerShown={datepickerShown}
          onChange={handleInputChange}
          placeholder={format(new Date(), DEFAULT_DATE_FORMAT)}
        />
      </div>
      {datepickerShown && (
        <div
          tabIndex={-1}
          style={popper.styles.popper}
          className="dialog-sheet z-100 w-min"
          {...popper.attributes.popper}
          ref={setPopperElement}
          role="dialog"
        >
          {showUPSPolicy && <Header header={<GuaranteedDatesInfo />} headerClassName={headerClassName} />}
          <DayPicker
            mode="single"
            components={{
              // eslint-disable-next-line react/no-unstable-nested-components
              DayContent: dayContentProps => {
                const { date } = dayContentProps;
                return (
                  <DateTime
                    dayType={getDayType(date)}
                    isSelected={
                      selectedDate.getDate() === date.getDate() && selectedDate.getMonth() === date.getMonth()
                    }
                    {...dayContentProps}
                  />
                );
              },
            }}
            formatters={{ formatCaption, formatWeekdayName }}
            modifiers={
              dates && {
                freeShipping,
                critical,
                standard,
              }
            }
            modifiersClassNames={{
              selected: selectedDayTypeClass || '!bg-neutral-500 !border-neutral-50 !border-4 !text-white',
              disabled: '!pointer-events-none !text-gray-700 !bg-transparent hover:!bg-transparent',
              freeShipping: 'modifiedclass hover:bg-green-50',
              critical: 'modifiedclass hover:bg-orange-50',
              standard: 'modifiedclass hover:bg-blue-50',
            }}
            classNames={{
              caption_label:
                'text-sm font-hvBold z-1 inline-flex items-center m-0 py-0 px-1 relative text-current whitespace-no-wrap',
              head_cell: 'text-xs font-hvBold h-10 p-0 text-center uppercase align-middle mb-5',
            }}
            styles={{
              caption: {
                marginBottom: '10px',
              },
              caption_label: {
                fontFamily: 'HelveticaNeueLTPro-Bd, sans-serif',
                fontSize: '16px',
                lineHeight: '24px',
                fontWeight: 600,
              },
              head_cell: {
                fontFamily: 'HelveticaNeueLTPro-Bd, sans-serif',
                fontSize: '12px',
                lineHeight: '14px',
                fontWeight: 600,
              },
              nav_button_next: { width: '9px !important', height: '5px !important', fontSize: '14px' },
              nav_button_previous: { width: '9px !important', height: '5px !important', fontSize: '14px' },
              caption_start: {
                borderRight: '1px solid #F1F5F9',
                margin: 0,
                marginBottom: '10px',
                marginTop: '20px',
                paddingRight: '1em',
                borderBottom: '1px solid #F1F5F9',
              },
              caption_end: {
                margin: 0,
                marginBottom: '10px',
                marginTop: '20px',
                borderBottom: '1px solid #F1F5F9',
              },
              months: {
                background: 'white',
                boxShadow: '0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -4px rgba(0, 0, 0, 0.1)',
                borderBottomLeftRadius: dates ? '0' : '12px',
                borderBottomRightRadius: dates ? '0' : '12px',
                borderTopLeftRadius: showUPSPolicy ? '0' : '12px',
                borderTopRightRadius: showUPSPolicy ? '0' : '12px',
                justifyContent: 'center',
              },
            }}
            showOutsideDays={false}
            disabled={disabledDays}
            onDayClick={handleDaySelect}
            selected={selectedDate}
            numberOfMonths={twoMonths ? 2 : 1}
          />
          {dates && (
            <Footer
              headerClassName={headerClassName}
              showSelectButton={!!onSelectTimeClick}
              selectedDay={selectedDate}
              onSelectTimeClick={handleSelectTimeClick}
              selectedDayType={selectedDayType}
              showCriticalDates={!!critical.length}
              showStandardDates={!!standard.length}
              showFreeShippingDates={!!freeShipping.length}
              twoMonths={twoMonths}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default DatePicker;
