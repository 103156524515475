/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable no-use-before-define */

import * as React from 'react';
import { DayContent, DayContentProps } from 'react-day-picker';
import { format } from 'date-fns';
import cn from 'classnames';

import { DEFAULT_DATE_FORMAT, DayType, BgColors } from '../constants';

interface Props extends DayContentProps {
  isSelected: boolean;
  dayType: DayType;
}

const DateTime = (props: Props) => {
  const { date, isSelected, dayType, activeModifiers } = props;
  const dateTime = format(date, DEFAULT_DATE_FORMAT);

  return (
    <time
      dateTime={dateTime}
      className={cn('group relative flex h-full w-full flex-col items-center justify-center')}
    >
      <DayContent {...props} />
      {!isSelected && !activeModifiers?.disabled && (
        <div
          className={cn(
            'visible absolute bottom-[2px] mt-1 h-[6px] w-[6px] rounded-full group-hover:invisible',
            BgColors[dayType],
          )}
        >
          {' '}
        </div>
      )}
    </time>
  );
};

export default DateTime;
