import CustomQuoteWarningButton from 'styleguide/components/CustomQuoteWarning/CustomQuoteWarningButton';
import Loader from 'styleguide/components/Loader/Loader';
import * as React from 'react';
import QuoteContext from 'contexts/QuoteContext/QuoteContext';
import PriceAndButtons from 'styleguide/components/ProductsTable/QuoteEditModal/PriceAndButtons/PriceAndButtons';

interface Props {
  onClose: () => void;
  setCustomQuoteModalOpened: (state: boolean) => void;
}

const Footer = ({ onClose, setCustomQuoteModalOpened }: Props) => {
  const quoteContext = React.useContext(QuoteContext);

  return (
    <>
      {quoteContext.status === 'customQuote' && (
        <CustomQuoteWarningButton onClick={() => setCustomQuoteModalOpened(true)} />
      )}
      {quoteContext.status === 'quoting' && <Loader dataCy="editFormLoadingSpinner" />}
      {(quoteContext.status === 'ready' || quoteContext.status === 'quoteError') && (
        <PriceAndButtons
          cancelable
          onCancel={onClose}
          disabled={false}
          priceAfter={quoteContext.price}
          priceBefore={quoteContext.initialPrice}
          errorMessage={quoteContext.errorMessage}
        />
      )}
    </>
  );
};

export default Footer;
